@if (loading()) {
  <pinnakl-local-spinner-simple-mode-container
    backgroundColor="rgba(255, 255, 255, 0.7)"
    class="block absolute top-0"
    containerHeight="100%"
    size="70px"
  ></pinnakl-local-spinner-simple-mode-container>
}

<div class="text-lg font-semibold mb-2">Security Lock</div>
<div class="flex">
  <div class="mr-2">Admin Security Lock:</div>
  @if (adminSecurityLock === undefined) {
    loading...
  } @else {
    <prime-input-switch
      [disabled]="true"
      [(ngModel)]="adminSecurityLock"
    ></prime-input-switch>
  }
</div>
<div class="text-lg font-semibold mt-3 b-2">User's Access and Security Settings</div>
<div class="access-control mt-2">
  <div
    *ngIf="selectedUsersCount"
    class="selected-users"
  >
    <span class="selected-users-amount">
      Edit login security for <strong>{{ selectedUsersCount }} users</strong>
    </span>
    <prime-button
      (click)="onEditLoginSecurityForMultipleUsers()"
      label="Edit"
      outlined="true"
      severity="primary"
      size="sm"
    ></prime-button>
  </div>
  <div
    *ngIf="!isAdminRole && accessControlForm"
    class="access-message"
  >
    You do not have authorization to make changes to Access control.
  </div>
  <form
    *ngIf="users?.length && accessControlForm"
    [formGroup]="accessControlForm"
    [ngClass]="{
      'container-with-message': !isAdminRole,
      'h-100': isAdminRole
    }"
  >
    <div class="form-controls-container">
      <div
        *ngFor="let user of users; let i = index"
        [formGroupName]="user.id.toString()"
        class="access-control-option"
      >
        <div class="user-info-container">
          <div class="select-user">
            <span class="input-check">
              <pinnakl-input
                [form]="accessControlForm.controls[user.id.toString()]"
                checkBoxStyle="input-check"
                controlName="selected"
                type="boolean"
              >
              </pinnakl-input>
            </span>
          </div>
          <name-avatar
            [height]="'32px'"
            [nameString]="user.fullName"
            [width]="'32px'"
          ></name-avatar>
          <div
            [ngClass]="{ 'flex-row': !isPlatform }"
            class="user-name"
          >
            <div class="name">
              <span>{{ user.fullName }}</span>
            </div>
            <div
              [ngClass]="{ 'ml-3': !isPlatform }"
              class="user-auth flex flex-row"
            >
              <span class="authtype">{{ getUserAuthTypeShort(user).label }}</span>
              <span
                *ngIf="getUserAuthTypeShort(user).icon"
                class="icon {{ getUserAuthTypeShort(user).icon }}"
              ></span>
            </div>
            <div
              [ngClass]="{
                'mt-1': isPlatform,
                'ml-1': !isPlatform
              }"
              class="flex"
            >
              <div class="text-sm text-color-secondary">Most recent login:</div>
              <div class="ml-2 text-sm text-color-secondary">
                {{
                  user.lastLoginAttempt ? (user.lastLoginAttempt | date: 'MM/dd/yy HH:mm:ss') : '-'
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="isPlatform"
          class="user-controls active-tooltip"
        >
          <button
            (click)="onEditSingleUserLoginSecurity($event, user)"
            *ngIf="user.rolesMap[UserRoles.ClientAdmin]"
            class="login-security"
          >
            <svg
              aria-hidden="true"
              class="svg-inline--fa fa-user-lock fa-w-20"
              data-icon="user-lock"
              data-prefix="fas"
              focusable="false"
              role="img"
              viewBox="0 0 640 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
          <div
            [ngClass]="{ 'disabled-toggle': !isAdminRole }"
            class="toggle-control"
          >
            <pinnakl-input
              [disabled]="!isAdminRole"
              [form]="accessControlForm.controls[user.id.toString()]"
              checkBoxStyle="toggle"
              controlName="tradingAccess"
              label="Trading"
              type="boolean"
            >
            </pinnakl-input>
          </div>
          <div
            [ngClass]="{ 'disabled-toggle': !isAdminRole }"
            class="toggle-control"
          >
            <pinnakl-input
              [disabled]="!isAdminRole"
              [form]="accessControlForm.controls[user.id.toString()]"
              checkBoxStyle="toggle"
              controlName="complianceAccess"
              label="Compliance"
              type="boolean"
            >
            </pinnakl-input>
          </div>
        </div>
      </div>
    </div>
    <div class="access-form-actions">
      <div>
        <button
          #anchor
          (click)="showFormCancelConfirmation()"
          [disabled]="!accessControlForm.dirty"
          class="btn btn-security btn-cancel"
          type="button"
        >
          CANCEL
        </button>
        <confirm-action
          (onCancelled)="cancelReset()"
          (onConfirmed)="resetForm()"
          [anchor]="anchor"
          [showConfirmation]="cancelConfirmationVisible"
          confirmationMessage="Are you sure you want to reset the form?"
        >
        </confirm-action>
      </div>
      <div>
        <button
          (click)="onSubmit()"
          [disabled]="!accessControlForm.dirty"
          class="btn btn-security btn-save"
          type="submit"
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>

<pinnakl-modal
  (closeModal)="toggleLoginSecurityModal()"
  [hideDialog]="hideLoginSecurityModal"
  [width]="632"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <login-security
          (cancelModal)="toggleLoginSecurityModal()"
          (updateUsers)="updateUsersLoginSecurity($event)"
          [users]="selectedUsers"
        ></login-security>
      </div>
    </div>
  </div>
</pinnakl-modal>
