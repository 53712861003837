import { inject, Injectable } from '@angular/core';
import { MultipleUsersUpdateItem } from '@pinnakl/core/data-providers';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { DataService } from '@pinnakl/shared/signal-store';
import { User, UserFromApi, UserRoles } from '@pinnakl/shared/types';
import { BuildUserRolesMap, getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService implements DataService<User, Record<string, any>> {
  private readonly usersEndpoint = 'v4/users';
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);

  async load(filter: any): Promise<User[]> {
    try {
      return await firstValueFrom(this.getUsers(filter));
    } catch (e) {
      console.log('Cannot load users. ' + getErrorMessage('', e));
      throw e;
    }
  }

  async loadById(id: number): Promise<User> {
    try {
      return await firstValueFrom(
        this.wsp.get<User>({
          endpoint: this.usersEndpoint
        })
      );
    } catch (e) {
      console.log('Cannot load user by id. ' + getErrorMessage('', e));
      throw e;
    }
  }

  async changeRole(userId: number, role: UserRoles, change: 'add' | 'remove'): Promise<void> {
    try {
      return await firstValueFrom(this.changeUserRole(userId, role, change));
    } catch (e) {
      this.toast.warning('Unable to change user role. ' + getErrorMessage('', e));
      throw e;
    }
  }

  async updateUser(user: Partial<User>): Promise<User> {
    try {
      return await firstValueFrom(this.putUser(user));
    } catch (e) {
      this.toast.warning('Unable to update user. ' + getErrorMessage('', e));
      throw e;
    }
  }

  async updateMultipleUsers(users: MultipleUsersUpdateItem[]): Promise<void> {
    try {
      return await firstValueFrom(this.updateUsersBatch(users));
    } catch (e) {
      this.toast.warning('Unable to update multiple users. ' + getErrorMessage('', e));
      throw e;
    }
  }

  private getUsers(filter: any): Observable<User[]> {
    return this.wsp
      .get<UserFromApi[]>({
        endpoint: `${this.usersEndpoint}/all`
      })
      .pipe(
        map((users: UserFromApi[]): User[] =>
          users.map(user => ({
            ...user,
            fullName: `${user.firstName} ${user.lastName}`,
            rolesMap: BuildUserRolesMap(user.roles)
          }))
        )
      );
  }

  private putUser(user: Partial<User>): Observable<User> {
    return this.wsp.put<User>({
      endpoint: `${this.usersEndpoint}`,
      body: user
    });
  }

  private changeUserRole(
    userId: number,
    role: UserRoles,
    change: 'add' | 'remove'
  ): Observable<void> {
    return this.wsp[change === 'add' ? 'put' : 'delete']({
      endpoint: `${this.usersEndpoint}/${userId}/roles/${role}`
    });
  }

  private updateUsersBatch(users: MultipleUsersUpdateItem[]): Observable<void> {
    return this.wsp.put({
      endpoint: `${this.usersEndpoint}/batch`,
      body: {
        users
      }
    });
  }
}
