<ng-container *ngIf="vm$ | async as vm">
  <section
    [ngClass]="{
      'mt-6': !isInvestorPortal
    }"
    class="authentication-page"
  >
    <div class="authentication-container">
      <div class="h-100">
        <div class="header">Settings</div>
        <div class="content">
          <div class="navigations relative">
            <ul class="w-full p-0">
              <li
                (click)="switchTab(settingsTabs.accountSettings)"
                [class.nav-option-active]="currentTab === settingsTabs.accountSettings"
                class="nav-option"
              >
                <a>Account settings</a>
                <div>Personal info, time zone</div>
              </li>
              <li
                (click)="switchTab(settingsTabs.loginSecurity)"
                [class.nav-option-active]="currentTab === settingsTabs.loginSecurity"
                class="nav-option"
              >
                <a>Login security</a>
                <div>Authentification type, number of factors</div>
              </li>
              <!--              <li class="nav-option"-->
              <!--                  *ngIf="vm.projectName !== ProjectNames.CRM_INVESTOR_PORTAL"-->
              <!--                  [class.nav-option-active]="currentTab === settingsTabs.notifications"-->
              <!--                  (click)="switchTab(settingsTabs.notifications)">-->
              <!--                <a>Notifications</a>-->
              <!--                <div>Toggle ON/OFF notifications for-->
              <!--                  trades, risks factors-->
              <!--                </div>-->
              <!--              </li>-->
              <li
                (click)="switchTab(settingsTabs.accessControl)"
                *ngIf="vm.projectName !== ProjectNames.INVESTOR_PORTAL"
                [class.nav-option-active]="currentTab === settingsTabs.accessControl"
                class="nav-option"
              >
                <a>Access control</a>
                <div>Set the level of access for trade info</div>
              </li>
              <li
                (click)="switchTab(settingsTabs.changePassword)"
                [class.nav-option-active]="currentTab === settingsTabs.changePassword"
                class="nav-option"
              >
                <a>Change Password</a>
                <div>Change your account password</div>
              </li>
              <li
                (click)="switchTab(settingsTabs.CRMSettings)"
                *ngIf="vm.projectName === ProjectNames.CRM"
                [class.nav-option-active]="currentTab === settingsTabs.CRMSettings"
                class="nav-option"
              >
                <a>Blocked IPs</a>
                <div>Select IPs that are blocked from CRM campaign tracking</div>
              </li>
              <li
                (click)="switchTab(settingsTabs.customAttributes)"
                *ngIf="vm.projectName === ProjectNames.CRM"
                [class.nav-option-active]="currentTab === settingsTabs.customAttributes"
                class="nav-option"
              >
                <a>Custom Fields</a>
                <div>Add/Edit custom fields</div>
              </li>
              <li
                (click)="switchTab(settingsTabs.emailCampaignConfig)"
                *ngIf="vm.projectName === ProjectNames.CRM"
                [class.nav-option-active]="currentTab === settingsTabs.emailCampaignConfig"
                class="nav-option"
              >
                <a>Email Campaign Configuration</a>
                <div>Configure settings for email campaigns</div>
              </li>

              <li
                (click)="switchTab(settingsTabs.WatermarkSettings)"
                *ngIf="vm.projectName === ProjectNames.CRM"
                [class.nav-option-active]="currentTab === settingsTabs.WatermarkSettings"
                class="nav-option"
              >
                <a>Watermarking Settings</a>
                <div>Configure default Watermarking Settings</div>
              </li>

              <li
                (click)="switchTab(settingsTabs.vdrSettings)"
                *ngIf="vm.projectName === ProjectNames.CRM && vm.clientConfig?.vdrEnabled"
                [class.nav-option-active]="currentTab === settingsTabs.vdrSettings"
                class="nav-option"
              >
                <a>Vdr Configuration</a>
                <div>Configure default Vdr settings</div>
              </li>
              <!-- <div>
                <a
                routerLink="/crm/crm-home/investor-custom-attributes"
                  [ngClass]="{
                    'crm-tab-div__activeButton': tabSelected === 'crm/crm-home/investor-custom-attributes'
                  }"
                  class="btn crm-tab-div__button"
                >
                Add/Edit Fields
                </a>
              </div> -->
            </ul>
            <div class="application-version">Version No: {{ version }}</div>
          </div>
          <div class="right-side">
            <ng-container [ngSwitch]="currentTab">
              <ng-container *ngSwitchCase="settingsTabs.accountSettings">
                <ng-container
                  *ngIf="vm.projectName === ProjectNames.INVESTOR_PORTAL; else accountSettings"
                >
                  <portal-account-settings
                    (updateVdrUser)="updateVdrUser($event)"
                    [vdrUser]="vm.vdrUser"
                    class="w-full"
                  ></portal-account-settings>
                </ng-container>
                <ng-template #accountSettings>
                  <account-settings
                    [user]="vm.user"
                    class="w-full"
                  ></account-settings>
                </ng-template>
              </ng-container>

              <ng-container *ngSwitchCase="settingsTabs.loginSecurity">
                <ng-container
                  *ngIf="vm.projectName === ProjectNames.INVESTOR_PORTAL; else loginSecurity"
                >
                  <portal-login-security
                    (updateVdrUser)="updateVdrUser($event)"
                    [vdrUser]="vm.vdrUser"
                    class="w-full"
                  >
                  </portal-login-security>
                </ng-container>
                <ng-template #loginSecurity>
                  <login-security
                    [isCurrentUserUpdate]="true"
                    [users]="[vm.user]"
                    class="w-full"
                  >
                  </login-security>
                </ng-template>
              </ng-container>

              <user-notifications
                *ngSwitchCase="settingsTabs.notifications"
                class="w-full"
              ></user-notifications>

              <access-control
                *ngSwitchCase="settingsTabs.accessControl"
                [currentUser]="vm.user"
                class="w-full"
              ></access-control>

              <change-password-page
                *ngSwitchCase="settingsTabs.changePassword"
                [projectName]="vm.projectName"
              ></change-password-page>

              <crm-settings
                *ngSwitchCase="settingsTabs.CRMSettings"
                class="w-full"
              ></crm-settings>

              <custom-attributes-field-list
                *ngSwitchCase="settingsTabs.customAttributes"
                class="w-full"
              ></custom-attributes-field-list>

              <ng-container *ngSwitchCase="settingsTabs.emailCampaignConfig">
                <email-campaign-config-settings
                  *ngIf="vm.clientConfig && vm.emailClientConfig"
                  [emailClientConfig]="vm.emailClientConfig"
                  [users]="vm.users"
                  class="w-full"
                ></email-campaign-config-settings>
              </ng-container>

              <crm-settings-watermark-settings
                *ngSwitchCase="settingsTabs.WatermarkSettings"
                class="w-full"
              >
              </crm-settings-watermark-settings>

              <vdr-configuration
                *ngSwitchCase="settingsTabs.vdrSettings"
                [users]="vm.users"
                class="w-full"
              ></vdr-configuration>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
