import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { CurrentUserStore, UsersStore } from '@pinnakl/core/data-providers';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@pinnakl/shared/constants';
import { User, userTimeZones } from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnChanges {
  private readonly currentUserStore = inject(CurrentUserStore);
  private readonly usersStore = inject(UsersStore);
  readonly availableTimezones = userTimeZones;

  cancelConfirmationVisible = false;
  accountForm: UntypedFormGroup;

  @Input() user: User;
  @Output() updateUser = new EventEmitter<User>();

  constructor(
    private readonly spinner: PinnaklSpinnerService,
    private readonly toastr: PinnaklUIToastMessage
  ) {
    this.accountForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      username: new FormControl(
        {
          value: null,
          disabled: true
        },
        {
          validators: Validators.required
        }
      ),
      email: new FormControl(
        {
          value: null,
          disabled: true
        },
        {
          validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)]
        }
      ),
      phone: new FormControl(null, [Validators.required, Validators.pattern(PHONE_PATTERN)]),
      timezone: new FormControl(null)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.resetForm();
    }
  }

  showFormCancelConfirmation(): void {
    this.cancelConfirmationVisible = true;
  }

  cancelReset(): void {
    this.cancelConfirmationVisible = false;
  }

  resetForm(): void {
    this.updateForm();
    this.cancelReset();
  }

  async onSubmit(): Promise<void> {
    const userId = this.currentUserStore.currentUser()?.id;
    if (!userId) return;
    const userAuthToSave = {
      firstName: this.accountForm.get('firstName')?.value,
      lastName: this.accountForm.get('lastName')?.value,
      phone: this.accountForm.get('phone')?.value,
      timeZone: this.accountForm.get('timezone')?.value
    };
    this.spinner.spin();
    try {
      const user = await this.usersStore.updateByIdUsersEntities(userId, userAuthToSave);
      await this.currentUserStore.updateUser(userAuthToSave);
      this.updateUser.emit(user);
      this.spinner.stop();
      this.toastr.success('Successfully changed');
    } catch (e) {
      this.toastr.error('Error in changing');
      throw e;
    } finally {
      this.spinner.stop();
    }
  }

  private updateForm(): void {
    this.accountForm.patchValue({
      firstName: this.user?.firstName,
      lastName: this.user?.lastName,
      username: this.user?.username,
      email: this.user?.email,
      phone: this.user?.phone,
      timezone: this.user?.timezone
    });
  }
}
