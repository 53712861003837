import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@pinnakl/shared/constants';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { VdrUsersApiService } from '@pinnakl/vdr/data-access';
import { VdrUser } from '@pinnakl/vdr/domain';

@Component({
  selector: 'portal-account-settings',
  templateUrl: './portal-account-settings.component.html',
  styleUrls: ['./portal-account-settings.component.scss']
})
export class PortalAccountSettingsComponent implements OnChanges {
  cancelConfirmationVisible = false;
  accountForm: UntypedFormGroup;

  @Input() vdrUser: VdrUser;
  @Output() updateVdrUser = new EventEmitter<VdrUser>();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly vdrUsersApi: VdrUsersApiService,
    private readonly spinner: PinnaklSpinnerService,
    private readonly toastr: PinnaklUIToastMessage
  ) {
    this.accountForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      emailAddress: [
        { value: null, disabled: true },
        [Validators.required, Validators.pattern(EMAIL_PATTERN)]
      ],
      phoneNumber: [null, [Validators.required, Validators.pattern(PHONE_PATTERN)]]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vdrUser) {
      this.resetForm();
    }
  }

  showFormCancelConfirmation(): void {
    this.cancelConfirmationVisible = true;
  }

  cancelReset(): void {
    this.cancelConfirmationVisible = false;
  }

  resetForm(): void {
    this.updateForm();
    this.cancelReset();
  }

  onSubmit(): void {
    const data = {
      firstName: this.accountForm.get('firstName')?.value,
      lastName: this.accountForm.get('lastName')?.value,
      emailAddress: this.accountForm.get('emailAddress')?.value.trim(),
      phoneNumber: this.accountForm.get('phoneNumber')?.value
    };

    this.spinner.spin();
    this.vdrUsersApi.updateCurrentVdrPortalUser(data).subscribe({
      next: vdrUser => {
        this.updateVdrUser.emit(vdrUser);
        this.resetForm();
        this.spinner.stop();
        this.toastr.success('Successfully changed');
      },
      error: () => {
        this.spinner.stop();
        this.toastr.error('Error in changing');
      }
    });
  }

  private updateForm(): void {
    this.accountForm.patchValue({
      firstName: this.vdrUser?.firstName,
      lastName: this.vdrUser?.lastName,
      emailAddress: this.vdrUser?.email,
      phoneNumber: this.vdrUser?.phoneNumber
    });
  }
}
