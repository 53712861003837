import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientsService } from '@pinnakl/core/data-providers';
import { ConfirmActionModule, SharedUiModule } from '@pinnakl/shared-ui';
import { LocalSpinnerSimpleModeContainerComponent } from '@pinnakl/shared/base-components';
import { WatermarkSettingsComponent } from '@pinnakl/shared/crm-settings/feature';
import {
  CustomAttributeRowItemComponent,
  CustomAttributesTableComponent
} from '@pinnakl/shared/custom-attributes/shared';
import {
  DataToOptionsPipe,
  FileDropDirective,
  SortByPipe
} from '@pinnakl/shared/ui/directives-and-pipes';
import {
  InputContainerComponent,
  InputValidationComponent,
  LabelContentComponent,
  PrimeButtonComponent,
  PrimeInputMaskComponent,
  PrimeInputSwitchComponent,
  PrimeInputTextComponent,
  PrimeSingleDropdownComponent
} from '@pinnakl/shared/ui/prime';
import { InputMaskModule } from 'primeng/inputmask';
import { AuthenticationModule } from '../authentication.module';
import { AccessControlComponent } from './access-control/access-control.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { CrmSettingsComponent } from './crm-settings/crm-settings.component';
import { CustomAtttributeComponent } from './custom-attributes-field-list/custom-attributes-field-creator/custom-attributes-field-creator.component';
import { CustomAttributesFieldListComponent } from './custom-attributes-field-list/custom-attributes-field-list.component';
import { EmailCampaignConfigSettingsComponent } from './email-campaign-config/email-campaign-config-settings.component';
import { TestEmailTemplateModalComponent } from './email-campaign-config/test-email-template-modal/test-email-template-modal.component';
import { LoginSecurityComponent } from './login-security/login-security.component';
import { PortalAccountSettingsComponent } from './portal-account-settings/portal-account-settings.component';
import { PortalLoginSecurityComponent } from './portal-login-security/portal-login-security.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { VdrConfigurationComponent } from './vdr-configuration/vdr-configuration.component';

@NgModule({
  declarations: [
    SettingsComponent,
    AccountSettingsComponent,
    PortalAccountSettingsComponent,
    LoginSecurityComponent,
    PortalLoginSecurityComponent,
    UserNotificationsComponent,
    AccessControlComponent,
    CrmSettingsComponent,
    CustomAtttributeComponent,
    EmailCampaignConfigSettingsComponent,
    TestEmailTemplateModalComponent,
    VdrConfigurationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SettingsRoutingModule,
    ReactiveFormsModule,
    AuthenticationModule,
    SharedUiModule,
    ConfirmActionModule,
    InputContainerComponent,
    InputValidationComponent,
    LabelContentComponent,
    PrimeInputMaskComponent,
    PrimeInputTextComponent,
    InputMaskModule,
    PrimeSingleDropdownComponent,
    DataToOptionsPipe,
    CustomAttributeRowItemComponent,
    SortByPipe,
    CustomAttributesTableComponent,
    CustomAttributesFieldListComponent,
    FileDropDirective,
    WatermarkSettingsComponent,
    PrimeButtonComponent,
    LocalSpinnerSimpleModeContainerComponent,
    PrimeInputSwitchComponent
  ],
  providers: [ClientsService],
  exports: [SettingsComponent]
})
export class SettingsModule {}
