import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { CurrentUserStore } from '@pinnakl/core/data-providers';
import { multiFactorTypes, OtpChannelType, userAuthTypes } from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { VdrUsersApiService } from '@pinnakl/vdr/data-access';
import { VdrUser } from '@pinnakl/vdr/domain';

@Component({
  selector: 'portal-login-security',
  templateUrl: './portal-login-security.component.html',
  styleUrls: ['./portal-login-security.component.scss']
})
export class PortalLoginSecurityComponent implements OnInit {
  private readonly currentUserStore = inject(CurrentUserStore);

  @Input() vdrUser: VdrUser;
  @Output() updateVdrUser = new EventEmitter<VdrUser>();
  readonly userAuthTypes = userAuthTypes;
  readonly multiFactorTypes = multiFactorTypes;
  cancelConfirmationVisible = false;
  form: UntypedFormGroup;

  constructor(
    private readonly vdrUsersApi: VdrUsersApiService,
    private readonly spinner: PinnaklSpinnerService,
    private readonly toastr: PinnaklUIToastMessage,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const otpChannel = this.vdrUser.otpChannel ? this.vdrUser.otpChannel : OtpChannelType.EMAIL;
    this.form = this.fb.group({
      otpChannel: [otpChannel, [this.otpChannelValidator.bind(this)]]
    });
  }

  otpChannelValidator(control: AbstractControl): ValidationErrors | null {
    const otpChannel = control.value;
    if (!this.vdrUser) return null;
    if (otpChannel === OtpChannelType.EMAIL) {
      return this.vdrUser.email ? null : { otpChannel: 'email address' };
    }
    if (otpChannel === OtpChannelType.MOBILE) {
      return this.vdrUser.phoneNumber ? null : { otpChannel: 'phone number' };
    }
    return null;
  }

  showFormCancelConfirmation(): void {
    this.cancelConfirmationVisible = true;
  }

  cancelReset(): void {
    this.cancelConfirmationVisible = false;
  }

  resetForm(): void {
    this.form.reset();
    this.cancelReset();
  }

  onSubmit(): void {
    const { otpChannel } = this.form.getRawValue();

    this.spinner.spin();
    this.vdrUsersApi.updateCurrentVdrPortalUser({ otpChannel }).subscribe(vdrUser => {
      const userInfo = this.currentUserStore.currentUserInfo();
      localStorage.setItem('name', userInfo.user.firstName);
      this.spinner.stop();
      this.updateVdrUser.emit(vdrUser);
      this.toastr.success('Successfully changed');
    });
  }
}
