import { Injectable } from '@angular/core';
import { VdrUser } from '@pinnakl/vdr/domain';
import { Observable } from 'rxjs';
import { VdrUsersApiService } from './vdr-users-api.service';

@Injectable({
  providedIn: 'root'
})
export class VdrUsersFacadeService {
  constructor(private readonly api: VdrUsersApiService) {}

  getVdrPortalUserById(id: number): Observable<VdrUser> {
    return this.api.getVdrPortalUserById(id);
  }
}
