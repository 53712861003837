{
  "name": "pnkl-frontend",
  "version": "10.2.249",
  "license": "MIT",
  "engines": {
    "node": ">=20.15"
  },
  "sideEffects": false,
  "browser": {
    "net": false,
    "tls": false,
    "crypto": false,
    "https": false
  },
  "scripts": {
    "ng": "ng",
    "nx": "nx",
    "start-custom:poems": "nx serve platform-web-app --configuration custom",
    "start-local:poems": "nx serve platform-web-app --configuration local",
    "start-dev:poems": "nx serve platform-web-app --configuration development",
    "start-stage:poems": "nx serve platform-web-app --configuration staging",
    "start-prod:poems": "nx serve platform-web-app --configuration production",
    "build-dev:poems": "nx build platform-web-app --configuration development",
    "build-stage:poems": "nx build platform-web-app --configuration staging",
    "build-prod:poems": "nx build platform-web-app --configuration production",
    "start-custom:crm": "nx serve crm-app --configuration custom",
    "start-local:crm": "nx serve crm-app --configuration local",
    "start-dev:crm": "nx serve crm-app --configuration development",
    "start-prod:crm": "nx serve crm-app --configuration production",
    "build-dev:crm": "nx build crm-app --configuration development",
    "build-prod:crm": "nx build crm-app --configuration production",
    "start-dev:portal": "nx serve investor-portal --configuration development",
    "start-prod:portal": "nx serve investor-portal --configuration production",
    "build-dev:portal": "nx build investor-portal --configuration development",
    "build-prod:portal": "nx build investor-portal --configuration production",
    "start-dev:pdf-viewer": "nx serve crm-documents --configuration development",
    "start-prod:pdf-viewer": "nx serve crm-documents --configuration production",
    "build-dev:pdf-viewer": "nx build crm-documents --configuration development",
    "build-prod:pdf-viewer": "nx build crm-documents --configuration production",
    "start-local:auth": "nx serve authenticator --configuration local --port=4201 --ssl",
    "start-dev:auth": "nx serve authenticator --configuration development --port=4201 --ssl",
    "start-prod:auth": "nx serve authenticator --configuration production --port=4201 --ssl",
    "build-dev:auth": "nx build --skip-nx-cache authenticator --configuration development",
    "build-prod:auth": "nx build --skip-nx-cache authenticator --configuration production",
    "test": "nx test",
    "test:all": "nx run-many -t test --exclude pnkl-frontend --parallel --codeCoverage",
    "lint": "nx affected --target=lint --quiet --parallel --exclude pnkl-frontend",
    "lint:all": "nx run-many -t lint --exclude pnkl-frontend --quiet --skip-nx-cache",
    "e2e": "ng e2e",
    "affected:apps": "nx affected:apps",
    "eslint:poems": "nx run-many --target=lint --projects=platform-web-app --quiet",
    "eslint:crm": "nx run-many --target=lint --projects=crm-app --quiet",
    "eslint:portal": "nx run-many --target=lint --projects=investor-portal --quiet",
    "affected:libs": "nx affected:libs",
    "affected:build": "nx affected:build",
    "affected:e2e": "nx affected:e2e",
    "affected:test": "nx affected:test",
    "affected:lint": "nx affected:lint",
    "affected:dep-graph": "nx affected:dep-graph",
    "affected": "nx affected",
    "format": "nx format:write",
    "format:write": "nx format:write",
    "format:check": "nx format:check",
    "update": "ng update @nrwl/workspace",
    "update:check": "ng update",
    "workspace-schematic": "nx workspace-schematic",
    "dep-graph": "nx dep-graph",
    "help": "nx help",
    "fix-migrations": "nx migrate --run-migrations",
    "nx-migrate": "nx migrate latest",
    "npm-ci": "npm ci",
    "prepare": "husky",
    "storybook:prime": "nx run shared-ui-prime:storybook",
    "proto:generate": "./node_modules/grpc-tools/bin/protoc --plugin=protoc-gen-ng=$(which protoc-gen-ng) --ng_out=libs/grpc/src/lib/models -I libs/grpc/protos $(find libs/grpc/protos -iname \"*.proto\")"
  },
  "PRIVATE": true,
  "dependencies": {
    "@angular/animations": "17.3.8",
    "@angular/cdk": "17.3.8",
    "@angular/common": "17.3.8",
    "@angular/compiler": "17.3.8",
    "@angular/core": "17.3.8",
    "@angular/forms": "17.3.8",
    "@angular/google-maps": "^17.3.8",
    "@angular/localize": "17.3.8",
    "@angular/material": "17.3.8",
    "@angular/platform-browser": "17.3.8",
    "@angular/platform-browser-dynamic": "17.3.8",
    "@angular/router": "17.3.8",
    "@azure/msal-angular": "^3.0.17",
    "@azure/msal-browser": "^3.14.0",
    "@fingerprintjs/fingerprintjs": "^4.3.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngrx/component-store": "17.2.0",
    "@ngrx/effects": "17.2.0",
    "@ngrx/entity": "17.2.0",
    "@ngrx/router-store": "17.2.0",
    "@ngrx/signals": "^17.2.0",
    "@ngrx/store": "17.2.0",
    "@ngrx/store-devtools": "17.2.0",
    "@ngx-grpc/common": "^3.1.2",
    "@ngx-grpc/core": "^3.1.2",
    "@ngx-grpc/grpc-web-client": "^3.1.2",
    "@ngx-grpc/well-known-types": "^3.1.2",
    "@progress/kendo-angular-buttons": "^8.2.1",
    "@progress/kendo-angular-charts": "^7.3.0",
    "@progress/kendo-angular-common": "^3.2.1",
    "@progress/kendo-angular-dateinputs": "^7.1.4",
    "@progress/kendo-angular-dialog": "^7.1.4",
    "@progress/kendo-angular-dropdowns": "^7.2.2",
    "@progress/kendo-angular-excel-export": "^5.0.2",
    "@progress/kendo-angular-grid": "^7.4.2",
    "@progress/kendo-angular-indicators": "3.0.1",
    "@progress/kendo-angular-inputs": "^10.1.1",
    "@progress/kendo-angular-intl": "^4.1.0",
    "@progress/kendo-angular-l10n": "^4.0.0",
    "@progress/kendo-angular-label": "^4.0.1",
    "@progress/kendo-angular-layout": "^7.1.5",
    "@progress/kendo-angular-pdf-export": "^4.0.0",
    "@progress/kendo-angular-popup": "5.0.1",
    "@progress/kendo-angular-progressbar": "^3.1.1",
    "@progress/kendo-angular-sortable": "^5.0.3",
    "@progress/kendo-angular-tooltip": "^4.0.3",
    "@progress/kendo-angular-treeview": "^7.1.4",
    "@progress/kendo-data-query": "^1.6.0",
    "@progress/kendo-drawing": "^1.17.1",
    "@progress/kendo-licensing": "^1.2.2",
    "@progress/kendo-svg-icons": "^1.5.0",
    "@progress/kendo-theme-default": "^5.10.0",
    "@reduxjs/toolkit": "^2.2.4",
    "@tinymce/tinymce-angular": "^8.0.0",
    "ag-grid-angular": "31.3.1",
    "ag-grid-community": "31.3.1",
    "ag-grid-enterprise": "31.3.1",
    "angular-auth-oidc-client": "17.0.0",
    "angular-svg-round-progressbar": "^12.0.0",
    "bootstrap": "4.6.1",
    "chart.js": "^4.4.2",
    "clientjs": "^0.2.1",
    "countries-and-timezones": "^3.6.0",
    "deep-diff": "1.0.2",
    "expr-eval": "^2.0.2",
    "firebase": "^10.11.1",
    "google-protobuf": "^3.21.2",
    "grpc-tools": "^1.12.4",
    "grpc-web": "^1.5.0",
    "hammerjs": "^2.0.8",
    "highcharts": "11.4.1",
    "highcharts-angular": "^4.0.0",
    "immer": "^10.1.1",
    "js-marker-clusterer": "^1.0.0",
    "jstat": "^1.9.6",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "ng-qrcode": "17.0.0",
    "ng2-currency-mask": "^13.0.3",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-clipboard": "^16.0.0",
    "ngx-timeago": "^3.0.0",
    "optiscroll": "^3.2.1",
    "pdfmake": "^0.2.10",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "~17.18.9",
    "quill": "^2.0.2",
    "regression": "^2.0.1",
    "rxjs": "~7.8.1",
    "ts-protoc-gen": "^0.15.0",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "xlsx": "^0.18.5",
    "zone.js": "0.14.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.7",
    "@angular-devkit/core": "17.3.7",
    "@angular-devkit/schematics": "17.3.7",
    "@angular-eslint/eslint-plugin": "~17.4.0",
    "@angular-eslint/eslint-plugin-template": "~17.4.0",
    "@angular-eslint/template-parser": "~17.4.0",
    "@angular/cli": "~17.3.7",
    "@angular/compiler-cli": "17.3.8",
    "@angular/language-service": "17.3.8",
    "@ngrx/schematics": "17.2.0",
    "@ngx-grpc/protoc-gen-ng": "^3.1.2",
    "@nrwl/angular": "19.0.1",
    "@nrwl/cypress": "19.0.1",
    "@nrwl/eslint-plugin-nx": "19.0.1",
    "@nrwl/jest": "19.0.1",
    "@nrwl/linter": "19.0.1",
    "@nrwl/workspace": "19.0.1",
    "@nx/esbuild": "19.0.1",
    "@nx/eslint": "19.0.1",
    "@nx/eslint-plugin": "19.0.1",
    "@nx/jest": "19.0.1",
    "@nx/js": "19.0.1",
    "@nx/storybook": "19.0.1",
    "@nx/web": "19.0.1",
    "@schematics/angular": "17.3.7",
    "@storybook/addon-essentials": "8.1.11",
    "@storybook/angular": "8.1.11",
    "@storybook/core-server": "8.1.11",
    "@storybook/manager-api": "8.1.11",
    "@swc-node/register": "1.9.1",
    "@swc/core": "~1.5.5",
    "@swc/helpers": "~0.5.11",
    "@types/clientjs": "^0.2.2",
    "@types/google-protobuf": "^3.15.12",
    "@types/google.maps": "^3.55.8",
    "@types/googlemaps": "^3.43.3",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/jest": "29.5.12",
    "@types/lodash": "^4.17.1",
    "@types/node": "20.12.11",
    "@types/react": "^18.3.1",
    "@types/react-dom": "^18.3.0",
    "@types/react-redux": "^7.1.33",
    "@types/redux-logger": "^3.0.13",
    "@typescript-eslint/eslint-plugin": "7.9.0",
    "@typescript-eslint/parser": "7.9.0",
    "@typescript-eslint/utils": "^8.0.0-alpha.28",
    "@vitest/ui": "^1.6.0",
    "cypress": "^13.9.0",
    "esbuild": "^0.21.1",
    "eslint": "8.57.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "^3.2.0",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-sonarjs": "^0.24.0",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.2",
    "jasmine-marbles": "~0.9.2",
    "jasmine-spec-reporter": "~7.0.0",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-preset-angular": "14.0.4",
    "jsonc-eslint-parser": "^2.4.0",
    "lint-staged": "^15.2.2",
    "npm-force-resolutions": "0.0.10",
    "nx": "19.0.1",
    "prettier": "^3.2.5",
    "prettier-plugin-organize-imports": "^3.2.4",
    "prettier-plugin-sort-json": "^4.0.0",
    "primereact": "^10.6.5",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "react-icons": "^5.2.1",
    "react-json-view": "^1.21.3",
    "sass": "1.77.0",
    "source-map": "^0.7.4",
    "ts-jest": "29.1.2",
    "ts-node": "10.9.2",
    "typescript": "5.4.5"
  },
  "nx": {
    "targets": {}
  }
}
