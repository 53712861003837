import { group } from '@angular/animations';
import {
  Component,
  effect,
  HostBinding,
  inject,
  Input,
  OnInit,
  signal,
  untracked
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientsService, CurrentUserStore, UsersStore } from '@pinnakl/core/data-providers';
import { EnvironmentService } from '@pinnakl/core/environment';
import { AppNames, OtpChannelType, User, UserAuthType, UserRoles } from '@pinnakl/shared/types';
import { filter } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.scss']
})
export class AccessControlComponent implements OnInit {
  private readonly currentUserStore = inject(CurrentUserStore);
  private readonly usersStore = inject(UsersStore);
  private readonly environmentService = inject(EnvironmentService);
  private readonly clientsService = inject(ClientsService);
  protected readonly UserRoles = UserRoles;
  users: User[];
  accessControlForm: UntypedFormGroup;
  cancelConfirmationVisible = false;
  selectedUsers: User[] = [];
  selectedUserIds = [];
  hideLoginSecurityModal = true;
  adminSecurityLock?: boolean = undefined;
  selectedUsersCount: number;
  isPlatform = this.environmentService.get('appName') === AppNames.PLATFORM_WEB;
  readonly loading = signal(true);
  @Input() currentUser: User;
  @HostBinding('class') componentClasses = 'relative';

  get isAdminRole(): boolean {
    return this.currentUserStore.currentUser()?.rolesMap[UserRoles.ClientAdmin];
  }

  constructor(private readonly fb: UntypedFormBuilder) {
    effect(() => {
      const users = this.usersStore.usersEntities();
      if (users) {
        untracked(() => this.loading.set(false));
        this.users = users;
        users.forEach(user => {
          group[user.id] = new UntypedFormGroup({
            tradingAccess: new UntypedFormControl({
              value: user.rolesMap[UserRoles.TradingAccess],
              disabled: !this.isAdminRole
            }),
            complianceAccess: new UntypedFormControl({
              value: user.rolesMap[UserRoles.ComplianceAccess],
              disabled: !this.isAdminRole
            }),
            id: new UntypedFormControl(user.id),
            selected: new UntypedFormControl({ value: false, disabled: !this.isAdminRole })
          });
        });
        this.accessControlForm = this.fb.group(group);
        this.accessControlForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
          this.checkSelectedUsers(value);
        });
      }
    });

    effect(() => {
      const clientDetails = this.clientsService.clientDetails();
      if (clientDetails) {
        this.adminSecurityLock = clientDetails.adminSecurityLock;
      }
    });
  }

  ngOnInit(): void {
    this.usersStore.loadUsersEntities({});
  }

  resetForm(): void {
    Object.keys(this.accessControlForm.controls).forEach(key =>
      this.accessControlForm.get(key).patchValue({ selected: false })
    );
  }

  showFormCancelConfirmation(): void {
    this.cancelConfirmationVisible = true;
  }

  cancelReset(): void {
    this.cancelConfirmationVisible = false;
  }

  async onSubmit(): Promise<void> {
    const requestsToExecute: {
      userId: number;
      change: 'add' | 'remove';
      role: UserRoles;
    }[] = [];
    this.users.forEach(user => {
      const userTradingAccessInitialValue = user.rolesMap[UserRoles.TradingAccess];
      const userComplianceAccessInitialValue = user.rolesMap[UserRoles.ComplianceAccess];
      const userFormValue = this.accessControlForm.getRawValue()[user.id.toString()];
      const userTradingAccessFormValue = userFormValue.tradingAccess;
      const userComplianceAccessFormValue = userFormValue.complianceAccess;

      const isTradingAccessChanged = userTradingAccessInitialValue !== userTradingAccessFormValue;
      const isComplianceAccessChanged =
        userComplianceAccessInitialValue !== userComplianceAccessFormValue;
      if (isTradingAccessChanged) {
        requestsToExecute.push({
          userId: user.id,
          change: userTradingAccessFormValue ? 'add' : 'remove',
          role: UserRoles.TradingAccess
        });
      }
      if (isComplianceAccessChanged) {
        requestsToExecute.push({
          userId: user.id,
          change: userComplianceAccessFormValue ? 'add' : 'remove',
          role: UserRoles.ComplianceAccess
        });
      }
    });

    this.loading.set(true);
    try {
      await Promise.all(
        requestsToExecute.map(({ userId, role, change }) =>
          this.usersStore.changeRole(userId, role, change)
        )
      );
      await this.usersStore.loadUsersEntities({}, true);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading.set(false);
    }
  }

  toggleLoginSecurityModal(): void {
    this.hideLoginSecurityModal = true;
  }

  onEditSingleUserLoginSecurity(event: any, user: User): void {
    event.stopPropagation();
    this.selectedUsers = [user];
    this.hideLoginSecurityModal = false;
  }

  onEditLoginSecurityForMultipleUsers(): void {
    this.selectedUsers = this.users.filter(
      user => !!this.selectedUserIds.find(id => id === user.id)
    );
    this.hideLoginSecurityModal = false;
  }

  updateUsersLoginSecurity(users: User[]): void {
    this.users = this.users.map(u => {
      const updatedUser = users.find(x => x.id === u.id);
      if (updatedUser) {
        return {
          ...u,
          ...updatedUser
        };
      }
      return u;
    });
    this.selectedUsers = [];
    this.resetForm();
    this.hideLoginSecurityModal = true;
  }

  getUserAuthTypeShort(user) {
    switch (user.authType) {
      case UserAuthType.SINGLE_FACTOR:
        return { label: '1 FA', icon: null };
      case UserAuthType.TWO_FACTOR:
        return { label: '2 FA', icon: this.getIconAuthOtpChannel(user.otpChannel) };
      case UserAuthType.SSO:
        return { label: '2 FA', icon: this.getIconAuthOtpChannel(user.otpChannel) };
      default:
        return { label: '1 FA', icon: null };
    }
  }

  getIconAuthOtpChannel(otpchannel) {
    switch (otpchannel) {
      case OtpChannelType.EMAIL:
        return 'icon-pinnakl-email';
      case OtpChannelType.MOBILE:
        return 'icon-pinnakl-mobile';
      case OtpChannelType.QR:
        return 'icon-pinnakl-qr';
    }
    return '';
  }

  private checkSelectedUsers(formValue: any): void {
    this.selectedUserIds = filter(formValue, x => x.selected).map(x => x.id);
    this.selectedUsersCount = this.selectedUserIds?.length;
  }
}
