import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  untracked
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@pinnakl/auth/providers';
import { ClientsService, CurrentUserStore, UsersStore } from '@pinnakl/core/data-providers';
import {
  AUTH_TYPES_MAP,
  multiFactorTypes,
  OTP_TYPES_MAP,
  OtpChannelType,
  requestAuthenticationHours,
  User,
  UserAuthType,
  userAuthTypes,
  UserRoles
} from '@pinnakl/shared/types';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const securityFormValidator: any = (fg: UntypedFormGroup) => {
  const authType = fg.get('authType')?.value;
  const otpChannel = fg.get('otpChannel')?.value;
  return (authType === UserAuthType.TWO_FACTOR &&
    Object.values(OtpChannelType).includes(otpChannel)) ||
    authType !== UserAuthType.TWO_FACTOR
    ? null
    : { isTwoFactorValid: false };
};

@Component({
  selector: 'login-security',
  templateUrl: './login-security.component.html',
  styleUrls: ['./login-security.component.scss']
})
export class LoginSecurityComponent {
  private readonly usersStore = inject(UsersStore);
  private readonly currentUserStore = inject(CurrentUserStore);
  private readonly clientsService = inject(ClientsService);
  readonly saveBtnDisabled = signal(true);

  @Input() isCurrentUserUpdate = false;
  @Output() updateUsers = new EventEmitter<any[]>();
  @Output() cancelModal = new EventEmitter<void>();
  qrCodeSecret$: Observable<string> = this.authService.otpSecret$.asObservable().pipe(
    distinctUntilChanged(),
    map(otpSecret => this.createQrCodeDecryptedValue(otpSecret ?? ''))
  );
  hideQrModalDialog = true;
  users: User[] = [];
  usersWithMissedPhone: any[] = [];
  usersWithMissedEmail: any[] = [];
  usersFullNamesWithMissedPhone = '';
  usersFullNamesWithMissedEmail = '';
  readonly userAuthTypes = userAuthTypes;
  readonly requestAuthenticationHours = requestAuthenticationHours;
  readonly multiFactorTypes = multiFactorTypes;
  cancelConfirmationVisible = false;
  loginSecurityForm: UntypedFormGroup;

  // eslint-disable-next-line
  @Input('users') set setUsers(value: User[]) {
    this.users = value || [];
    this.usersWithMissedEmail = this.getUsersWithMissedEmail(this.users);
    this.usersWithMissedPhone = this.getUsersWithMissedPhone(this.users);
    this.resetForm();
  }

  get isMultiFactorSelected(): boolean {
    return this.loginSecurityForm?.get('authType')?.value === UserAuthType.TWO_FACTOR;
  }

  constructor(
    private readonly authService: AuthService,
    private readonly spinner: PinnaklSpinnerService,
    private readonly toastr: PinnaklUIToastMessage
  ) {
    effect(() => {
      const clientDetails = this.clientsService.clientDetails();
      if (clientDetails) {
        const user = this.currentUserStore.currentUser();
        if (user) {
          if (user.rolesMap[UserRoles.ClientAdmin] || !clientDetails.adminSecurityLock) {
            untracked(() => this.saveBtnDisabled.set(false));
          }
        }
      }
    });
  }

  showFormCancelConfirmation(): void {
    this.cancelConfirmationVisible = true;
  }

  cancelReset(): void {
    this.cancelConfirmationVisible = false;
  }

  resetForm(): void {
    this.initLoginSecurityForm();
    this.cancelReset();
  }

  onCancelModal(): void {
    this.cancelModal.emit();
  }

  async onSubmit(): Promise<void> {
    this.spinner.spin();
    let updatePayload = [];
    if (this.isCurrentUserUpdate) {
      const userAuthToSave = {
        userId: this.users[0]?.id,
        authType: this.loginSecurityForm.get('authType')?.value,
        tokenReAuthInterval: this.loginSecurityForm.get('tokenReAuthInterval')?.value
      };
      updatePayload.push(userAuthToSave);
    } else {
      this.users.forEach(u => {
        const userAuthToSave = {
          userId: u?.id,
          authType: this.loginSecurityForm.get('authType')?.value,
          tokenReAuthInterval: this.loginSecurityForm.get('tokenReAuthInterval')?.value
        };
        updatePayload.push(userAuthToSave);
      });
    }

    const otpValue = this.loginSecurityForm.get('otpChannel')?.value;

    if (this.isCurrentUserUpdate && this.isMultiFactorSelected) {
      updatePayload = updatePayload.map(user => ({
        ...user,
        otpChannel: otpValue
      }));
    } else if (this.isMultiFactorSelected) {
      updatePayload = updatePayload.map(user => {
        let is2FAEnabled = false;
        if (otpValue === this.multiFactorTypes.email.value) {
          is2FAEnabled = !this.usersWithMissedEmail.find(u => u.id === user.id)?.id;
        } else if (otpValue === this.multiFactorTypes.mobile.value) {
          is2FAEnabled = !this.usersWithMissedPhone.find(u => u.id === user.id)?.id;
        } else if (otpValue === this.multiFactorTypes.qr.value) {
          is2FAEnabled = true;
        }

        if (is2FAEnabled) {
          return {
            ...user,
            otpChannel: otpValue
          };
        }
        return user;
      });
    }

    try {
      if (this.isCurrentUserUpdate) {
        await this.currentUserStore.updateUser({
          ...updatePayload[0]
        });
        this.toastr.success('Successfully updated');
      } else {
        await this.usersStore.updateMultipleUsers(updatePayload);
        this.toastr.success('Successfully updated');
        this.updateUsers.emit([]);
      }
    } catch (e) {
      if (this.isCurrentUserUpdate) {
        this.toastr.error(
          'Failed to update login security of this user. ' + getErrorMessage('', e)
        );
      } else {
        this.toastr.error('Failed to update users. ' + getErrorMessage('', e));
      }
      throw e;
    } finally {
      this.spinner.stop();
    }
  }

  updateQrSecret() {
    this.authService.updateQrSecret(() => this.showQrModal());
  }

  showQrModal(): void {
    this.hideQrModalDialog = false;
  }

  closeQrModal(): void {
    this.hideQrModalDialog = true;
  }

  private initLoginSecurityForm(): void {
    if (this.isCurrentUserUpdate || this.users.length === 1) {
      const userAuthType = this.users[0]?.authType;
      const authType = AUTH_TYPES_MAP[userAuthType] ?? userAuthType;
      const userOtpChannel = this.users[0]?.otpChannel;
      const otpChannel = OTP_TYPES_MAP[userOtpChannel] ?? userOtpChannel;
      this.loginSecurityForm = new UntypedFormGroup(
        {
          authType: new UntypedFormControl(authType ?? null, [Validators.required]),
          otpChannel: new UntypedFormControl(
            authType === UserAuthType.TWO_FACTOR ? otpChannel : null
          ),
          tokenReAuthInterval: new UntypedFormControl(this.users[0]?.tokenReauthInterval, [
            Validators.required
          ])
        },
        { validators: [securityFormValidator] }
      );
    } else {
      this.loginSecurityForm = new UntypedFormGroup(
        {
          authType: new UntypedFormControl(null, [Validators.required]),
          otpChannel: new UntypedFormControl(null),
          tokenReAuthInterval: new UntypedFormControl(null, [Validators.required])
        },
        { validators: [securityFormValidator] }
      );
    }
    this.loginSecurityForm?.get('authType').valueChanges.subscribe(authType => {
      this.loginSecurityForm.patchValue({ otpChannel: null });
    });
  }

  private getUsersWithMissedPhone(users: User[]): User[] {
    const result = users.filter(u => !u.phone);
    this.usersFullNamesWithMissedPhone = result.map(x => x.fullName).join(', ');
    return result;
  }

  private getUsersWithMissedEmail(users: User[]): User[] {
    const result = users.filter(u => !u.email);
    this.usersFullNamesWithMissedEmail = result.map(x => x.fullName).join(', ');
    return result;
  }

  private createQrCodeDecryptedValue(secretKey: string, issuer = `Pinnakl`): string {
    const key = secretKey ? secretKey : this.currentUserStore.currentUser()?.otpSecret;
    return `otpauth://totp/${issuer}?secret=${key}`;
  }
}
